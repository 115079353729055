'use client'; // Error boundaries must be Client Components

import { Button } from '@/components/button';
import { merriweather_sans } from '@/lib/utils/fonts';
import { useEffect } from 'react';

export default function Error({ error, reset }) {
    useEffect(() => {
        // Log the error to an error reporting service
        console.error(error);
    }, [error]);

    return (
        <div
            className={`${merriweather_sans.className} font-en`}
            style={{
                '--btn-primary': '#C81020',
                '--btn-primary-hover': '#990c23',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <section className="section">
                <div className="container text-center">
                    <h2 className="mb-2">Something went wrong!</h2>
                    <p>Message: {error.message}</p>
                    <Button
                        btn_size="lg"
                        btn_style="primary"
                        trigger={
                            // Attempt to recover by trying to re-render the segment
                            () => reset()
                        }
                    >
                        Try again
                    </Button>
                </div>
            </section>
        </div>
    );
}
